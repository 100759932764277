import React from "react";
import { RouteComponentProps } from "react-router-dom";
import api from "../api";
import LineItem from "../components/LineItem";
import { Loading } from "../components/Loading";
import { OrderItem } from "../models";
import { Button, Form, InputGroup, Col } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";

interface Props {
    q?: string;
}

interface State {
    items: OrderItem[];
    loading: boolean;
    searchQuery: string;
}

const defaultState = {
    items: [],
    searchQuery: "",
    loading: true
};

export class OrderItems extends React.Component<RouteComponentProps<Props>, State> {
    state = defaultState;
    async componentDidMount(): Promise<void> {
        const { q } = this.props.match.params;
        if (q) {
            this.setState({ searchQuery: q });
            const { items } = await api.searchOrderItems(q);
            this.setState({
                loading: false,
                items
            });
        } else {
            this.setState({ loading: false });
        }
    }

    render(): JSX.Element {
        const search = (
            <div>
                <Form onSubmit={this.handleSearch} className="item-search">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <FiSearch />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className="simple-input"
                            value={this.state.searchQuery}
                            onChange={this.handleSearchChange}
                            placeholder="Customer Name or Order Number"
                            type="text"
                        />
                        <InputGroup.Append>
                            <Button type="submit" variant="success">
                                Search
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form>
            </div>
        );
        let list;
        if (this.state.loading) {
            list = (
                <div className="text-center mt-5">
                    <Loading />
                </div>
            );
        } else if (!this.state.items) {
            list = <p className="text-center">Click Search to Start</p>;
        } else if ((this.state.items as OrderItem[]).length < 1) {
            list = <p className="text-center">No Items found</p>;
        } else {
            list = (this.state.items as OrderItem[]).map((item: OrderItem, idx: number) => (
                <LineItem idx={idx} item={item} key={item.id} />
            ));
        }

        return (
            <div>
                <Col className="mt-3" sm={12}>
                    {search}
                </Col>
                <Col className="mt-3" sm={12}>
                    {list}
                </Col>
            </div>
        );
    }

    handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const currentTarget = evt.currentTarget;
        this.setState({
            searchQuery: currentTarget.value
        });
    };

    handleSearch = async (): Promise<void> => {
        const { searchQuery } = this.state;
        const { q } = this.props.match.params;
        if (q === searchQuery) return;
        this.props.history.push(`${searchQuery}`);
    };
}
