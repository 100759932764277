import * as React from "react";
import { EditImage } from "./EditImage";
import ThumborImage from "../lib/ThumborImage";

interface Props {
    src: string;
    onImageChanged: (newUrl: string) => Promise<void>;
    onImageDelete: (url: string) => Promise<void>;
}

interface State {
    isOpen: boolean;
    displaySrc: string; // URL to display (thumbnail) version of image
    imgReference: string; // Need this to store actual URL stored in shopify for reference
    validUrl: boolean;
}

const initialState = {
    isOpen: false,
    displaySrc: "#",
    validUrl: false
};

const thumbFit = { width: 300, height: 300 };

export default class EditableImage extends React.Component<Props, State> {
    image = React.createRef<HTMLImageElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            ...initialState,
            imgReference: props.src
        };
    }

    async loadImage(src: string): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject();
            img.src = src;
        }).catch(e => {
            throw new Error(e);
        });
    }

    async componentDidMount(): Promise<void> {
        const { src } = this.props;
        const thumborImage = ThumborImage.fromUrl(src);
        try {
            const displaySrc = await thumborImage.toString(thumbFit);
            await this.loadImage(displaySrc);
            this.setState({
                validUrl: true,
                displaySrc
            });
        } catch (e) {
            this.setState({
                validUrl: false
            });
        }
    }

    render(): JSX.Element {
        const { displaySrc, validUrl } = this.state;

        if (!validUrl) {
            return <></>;
        }

        return (
            <button onClick={() => this.toggleModal(true)}>
                <img
                    style={{
                        cursor: "pointer"
                    }}
                    src={displaySrc}
                    alt="Check-In Upload"
                    className="img-fluid"
                />
                {this.state.isOpen && (
                    <EditImage
                        imgSrc={displaySrc}
                        updateDisplaySrc={this.updateDisplaySrc}
                        onSave={this.handleImageChange}
                        onDelete={this.handleImageDelete}
                        onFinish={this.closeModal}
                    />
                )}
            </button>
        );
    }

    private handleImageDelete = async () => {
        await this.props.onImageDelete(this.state.imgReference);
    };

    private handleImageChange = async (newUrl: string) => {
        await this.props.onImageChanged(newUrl);
        this.setState({
            imgReference: newUrl
        });
    };

    private toggleModal = (val: boolean) => {
        this.setState({
            isOpen: val
        });
    };

    private closeModal = () => {
        this.toggleModal(false);
    };

    private updateDisplaySrc = async (img: string) => {
        this.setState({
            displaySrc: img
        });
    };
}
