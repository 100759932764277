import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import api from "../api";
import Alert from "react-bootstrap/Alert";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {
    authenticate: () => void;
}

interface State {
    username: string;
    password: string;
    message: string | null;
}

const defaultState = {
    username: "",
    password: "",
    message: null
};

class AuthForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = defaultState;
    }

    render() {
        return (
            <Card className="auth-form">
                <Card.Title>Log In</Card.Title>
                <Form onSubmit={this.handleSubmit}>
                    <Alert variant="danger" style={{ display: this.state.message === null ? "none" : "block" }}>
                        {this.state.message}
                    </Alert>

                    <Form.Group controlId="username">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={this.handleChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Log In
                    </Button>
                </Form>
            </Card>
        );
    }
    handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const authResult = await api.authenticate(this.state.username, this.state.password);
        if (!authResult) {
            this.setState({ message: "Bad credentials.  Please try again." });
            this.props.authenticate();
        } else {
            this.setState({ message: null });
            await this.props.authenticate();
            const redirectTo = this.props.location.state ? (this.props.location.state as { from: URL }).from.pathname : "/";
            this.props.history.push(redirectTo);
        }
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const currentTarget = event.currentTarget;
        this.setState({
            ...this.state,
            [currentTarget.id]: currentTarget.value
        });
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const authForm = (authenticate: () => void) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.assign((props: RouteComponentProps<any>) => <AuthForm {...props} authenticate={authenticate} />, {
        displayName: "AuthForm"
    });

export default authForm;
