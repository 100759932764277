import axios from "axios";

const cred = localStorage.getItem("credentials");

export const axiosS3 = axios.create();

export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASEURL,
    auth: cred ? JSON.parse(cred) : undefined
});
