import React, { FunctionComponent } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

interface Props {
    authenticated: boolean;
    logout: () => void;
}

const Layout: FunctionComponent<Props> = ({ logout, authenticated, children }) => {
    return (
        <>
            <Header logout={logout} authenticated={authenticated} />
            <div className="App">
                <Container>{children}</Container>
            </div>
            <Footer />
        </>
    );
};

export default Layout;
