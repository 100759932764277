import axios from "axios";
import React, { FunctionComponent } from "react";
import { Button, Form } from "react-bootstrap";
import { createPortal } from "react-dom";
import { FiRotateCcw, FiRotateCw } from "react-icons/fi";
import { Loading } from "./Loading";
import ReactCrop from "react-image-crop";
import ThumborImageInterface, { Crop } from "../lib/ThumborImage";
import "react-image-crop/dist/ReactCrop.css";

const editorFit = { width: 1800, height: 1800 };
const displayFit = { width: 300, height: 300 };

// might be easier to make State.crop nullable?
const nullCrop = { x: 0, y: 0, height: 0, width: 0 };
const imageSize = {
    maxHeight: "80vh",
    maxWidth: "100%"
};
interface Props {
    imgSrc: string;
    updateDisplaySrc: (url: string) => Promise<void>;
    onFinish: () => void;
    onSave: (newUrl: string) => Promise<void>;
    onDelete: () => Promise<void>;
}

interface State {
    crop: ReactCrop.Crop;
    cropped: boolean;
    loading: boolean;
    thumborImage: ThumborImageInterface;
    editorSrc: string;
    deleting: boolean;
    deleteClicked: boolean;
}

const initialState = {
    crop: nullCrop,
    cropped: false,
    loading: true,
    thumborImage: ThumborImageInterface.fromUrl("http://www.example.com/test.jpg"),
    editorSrc: "",
    deleting: false,
    deleteClicked: false
};

const ModalContent: FunctionComponent = props => {
    return createPortal(<div className="portal">{props.children}</div>, document.body);
};

export class EditImage extends React.Component<Props, State> {
    state = initialState;
    _isMounted = false;
    async componentDidMount(): Promise<void> {
        this._isMounted = true;
        const { imgSrc } = this.props;
        try {
            const thumborInstance = ThumborImageInterface.fromUrl(imgSrc);
            let newState: State = {
                ...initialState,
                loading: false,
                editorSrc: await thumborInstance.withoutCrop().toString(editorFit),
                thumborImage: thumborInstance
            };

            const pctCrop = (await thumborInstance.getPercentCrop()) || nullCrop;
            if (pctCrop !== nullCrop) {
                newState = {
                    ...newState,
                    crop: pctCrop,
                    cropped: true
                };
            }

            this.setState(newState);
        } catch (e) {
            console.log(`Error loading ${this.props.imgSrc}`, e);
        }
    }

    async componentWillUnmount(): Promise<void> {
        this._isMounted = false;
    }

    render(): JSX.Element {
        const { crop, cropped, editorSrc } = this.state;

        let edit = <ReactCrop imageStyle={imageSize} onChange={this.onCropChange} crop={crop} src={editorSrc} />;

        if (!cropped) {
            edit = <img style={imageSize} alt="Current Editable" src={editorSrc} />;
        }

        if (this.state.loading) {
            edit = <Loading logoWhite />;
        }

        const buttons = (
            <div className="buttons">
                <Form.Check
                    checked={cropped}
                    onChange={this.handleCropCheck}
                    type="checkbox"
                    id="crop-checkbox"
                    label="Crop"
                />
                <Button onClick={() => this.handleRotate(false)} size="sm" variant="primary">
                    <FiRotateCcw />
                </Button>
                <Button onClick={() => this.handleRotate(true)} size="sm" variant="primary">
                    <FiRotateCw />
                </Button>
                <Button onClick={this.handleSave} size="sm" variant="success">
                    Save
                </Button>
                <Button size="sm" variant="warning" onClick={this.handleCancel}>
                    Cancel
                </Button>
                <Button size="sm" disabled={this.state.deleting} variant="danger" onClick={this.handleDelete}>
                    {this.state.deleteClicked ? "Delete this image?" : "Delete"}
                </Button>
            </div>
        );

        return (
            <ModalContent>
                <div className="image-editor">
                    <div
                        className="image-container"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {edit}
                    </div>
                    {buttons}
                </div>
            </ModalContent>
        );
    }

    private handleCropCheck = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ cropped: e.currentTarget.checked });
    };

    private onCropChange = (crop: ReactCrop.Crop) => {
        this.setState({ crop });
    };

    private handleRotate = async (cw: boolean) => {
        this.setState({ loading: true });
        const { crop, thumborImage } = this.state;
        let newCrop, newThmbr;
        if (!thumborImage) {
            return;
        }
        if (cw) {
            newCrop = {
                x: 100 - (crop.y + crop.height),
                y: crop.x,
                width: crop.height,
                height: crop.width
            };

            newThmbr = thumborImage.rotateCw();
        } else {
            newCrop = {
                x: crop.y,
                y: 100 - (crop.x + crop.width),
                width: crop.height,
                height: crop.width
            };

            newThmbr = thumborImage.rotateCcw();
        }
        const editorSrc = await newThmbr.withoutCrop().toString(editorFit);
        await axios.get(editorSrc);

        this.setState({
            crop: newCrop,
            loading: false,
            thumborImage: newThmbr,
            editorSrc
        });
    };

    private handleSave = async () => {
        const { cropped, crop, thumborImage } = this.state;
        if (!thumborImage) {
            return;
        }

        let newThumborImage = thumborImage.withoutCrop();
        this.setState({
            loading: true
        });

        if (cropped && crop) {
            const newCrop: Crop = {
                height: crop.height,
                width: crop.width,
                x: crop.x,
                y: crop.y,
                type: "percent",
            };
            newThumborImage = thumborImage.withCrop(newCrop);
        }

        const [newUrl, newThumbnail] = await Promise.all<string>([
            newThumborImage.toString(),
            newThumborImage.toString(displayFit)
        ]);

        await Promise.all([
            axios.get(newThumbnail),
            this.props.updateDisplaySrc(newThumbnail),
            this.props.onSave(newUrl)
        ]);

        this.setState({
            loading: false,
            thumborImage: newThumborImage,
            editorSrc: await thumborImage.withoutCrop().toString(editorFit)
        });
        this.props.onFinish();
    };

    private handleCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        this.props.onFinish();
    };

    private handleDelete = async () => {
        if (this.state.deleteClicked) {
            this.setState({ deleting: true });
            await this.props.onDelete();
            return;
        }
        this.setState({
            deleteClicked: true
        });

        setTimeout(() => {
            this._isMounted && this.setState({
                deleteClicked: false
            });
        }, 4000);
    };
}
