import React from "react";
import { Form, Navbar, Button } from "react-bootstrap";

interface Props {
    authenticated: boolean;
    logout: () => void;
}

const Header = ({ authenticated, logout }: Props) => {
    const username = authenticated ? JSON.parse(localStorage.getItem("credentials") as string).username : "";
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">FFP Check-In</Navbar.Brand>
            <div className="mr-auto" />
            <Form inline>
                {username}
                {authenticated ? (
                    <Button className="ml-2" size="sm" onClick={logout}>
                        Logout
                    </Button>
                ) : (
                    ""
                )}
            </Form>
        </Navbar>
    );
};

export default Header;
