import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import api from "./api";
import "./App.css";
import { Loading } from "./components/Loading";
import Layout from "./components/Layout";
import authForm from "./pages/AuthForm";
import { OrderItems } from "./pages/OrderItems";
import "./style.scss";

interface State {
    loading: boolean;
    authenticated: boolean;
}

const defaultState: State = {
    loading: true,
    authenticated: false
};

class App extends Component {
    state = defaultState;
    render(): JSX.Element {
        return (
            <Layout logout={this.logout} authenticated={this.state.authenticated}>
                {this.state.loading ? this.renderLoading() : this.renderRoutes()}
            </Layout>
        );
    }

    /*
     *
     * The following is a bit tricky.  We're using property-renaming, which is
     * non-obvious, but needed since component needs to be uppercase.:
     * https://www.typescriptlang.org/docs/handbook/variable-declarations.html#property-renaming
     */
    // There's not a particularly good way to avoid `any` here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ProtectedRoute = ({ component: Component, ...rest }: { component: any; path: string }): JSX.Element => {
        return (
            <Route
                {...rest}
                render={props => {
                    if (this.state.authenticated) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                    }
                }}
            />
        );
    };

    private renderLoading() {
        return (
            <div className="text-center mt-5">
                <Loading />
            </div>
        );
    }

    private renderRoutes() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route path="/login" render={authForm(this.authenticate)} />
                        <this.ProtectedRoute
                            path="/:q"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            component={(p: any) => <OrderItems {...p} key={p.match.params.q} />}
                        />
                        <this.ProtectedRoute path="/" component={OrderItems} />
                        {/* <this.ProtectedRoute path="/:nomatch" component={notFound} /> */}
                    </Switch>
                </Router>
            </div>
        );
    }

    async componentDidMount(): Promise<void> {
        await this.authenticate();
        this.setState({ loading: false });
    }

    authenticate = async (): Promise<void> => {
        const pingResult = await api.ping();
        this.setState({
            authenticated: pingResult
        });
    };

    logout = (): void => {
        api.logout();
        this.setState({
            authenticated: false
        });
    };
}

export default App;
