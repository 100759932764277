import { Attribute, OrderItem as LineItemModel } from "../models";
import * as React from "react";
import Api from "../api";
import { AxiosProgressEvent } from "axios";
import { Button, Card, Tabs, Tab, Form, Col } from "react-bootstrap";

import EditableImage from "./EditableImage";

interface Props {
    item: LineItemModel;
    idx: number;
}

const basename = (url: string) => {
    return url.split("/").pop();
};
interface State {
    item: LineItemModel;
    imgUploading: boolean;
    imgUploadingErrorFiles: string[];
    uploadProgress: number;
    markArrivedLoading: boolean;
    markArrivedHide: boolean;
    markDamagedLoading: boolean;
    markDamagedHide: boolean;
    markQcLoading: boolean;
    markQcHide: boolean;
    artReceivedDateString: string;
    artDamagedDateString: string;
    artQcDateString: string;
    damageStatus: string;
    damageStatusError: boolean;
    updatedArtWidth: string;
    updatedArtHeight: string;
    correctedArtTier: boolean;
    artOrientation: string;
}

const attrVal = (attributes: Attribute[], key: string) => {
    const found = attributes.find(a => a.key === key);
    return found ? found.value : null;
};

export default class LineItem extends React.Component<Props, State> {
    // private fileInputRef : React.RefObject<HTMLInputElement>;

    private fileInputRef = React.createRef<HTMLInputElement>();
    private fileDamagedInputRef = React.createRef<HTMLInputElement>();
    private fileQcInputRef = React.createRef<HTMLInputElement>();
    state: State;

    constructor(props: Props) {
        super(props);
        this.state = {
            item: props.item,
            imgUploading: false,
            imgUploadingErrorFiles: [],
            markArrivedLoading: false,
            markArrivedHide: false,
            markDamagedLoading: false,
            markDamagedHide: false,
            markQcLoading: false,
            markQcHide: false,
            uploadProgress: 0,
            artReceivedDateString: "",
            artDamagedDateString: "",
            artQcDateString: "",
            damageStatus: "",
            damageStatusError: false,
            updatedArtHeight: "",
            updatedArtWidth: "",
            correctedArtTier: false,
            artOrientation: ""
        };
    }

    componentDidMount(): void {
        let artReceivedAt = attrVal(this.state.item.order.meta, "art_received_at_" + this.state.item.id);
        if (!artReceivedAt) {
            artReceivedAt = attrVal(this.state.item.order.meta, "art_received_at");
        }
        if (artReceivedAt) {
            const receive: Record<string, string> = JSON.parse(artReceivedAt);
            const artReceived = Object.values(receive)[0].toString();
            const artReceivedDate = new Date(artReceived);
            this.setState({ artReceivedDateString: artReceivedDate.toString() });
        }

        let artDamagedAt = attrVal(this.state.item.order.meta, "art_damaged_at_" + this.state.item.id);
        if (!artDamagedAt) {
            artDamagedAt = attrVal(this.state.item.order.meta, "art_damaged_at");
        }
        if (artDamagedAt) {
            const damage: Record<string, string> = JSON.parse(artDamagedAt);
            const artDamaged = Object.values(damage)[0].toString();
            const artDamagedDate = new Date(artDamaged);
            this.setState({ artDamagedDateString: artDamagedDate.toString() });
        }

        let artQcAt = attrVal(this.state.item.order.meta, "art_qc_at_" + this.state.item.id);
        if (!artQcAt) {
            artQcAt = attrVal(this.state.item.order.meta, "art_qc_at");
        }
        if (artQcAt) {
            const qc: Record<string, string> = JSON.parse(artQcAt);
            const artQc = Object.values(qc)[0].toString();
            const artQcDate = new Date(artQc);
            this.setState({ artQcDateString: artQcDate.toString() });
        }

        let damageStatus = attrVal(this.state.item.order.meta, "damage_status_" + this.state.item.id);
        if (!damageStatus) {
            damageStatus = attrVal(this.state.item.order.meta, "damage_status");
        }
        if (damageStatus) {
            const damageValue: Record<string, string> = JSON.parse(damageStatus);
            const damageState = Object.values(damageValue)[0].toString();
            this.setState({ damageStatus: damageState });
        }

        let updatedArtWidth = attrVal(this.state.item.order.meta, "corrected_art_width_" + this.state.item.id);
        if (!updatedArtWidth) {
            updatedArtWidth = attrVal(this.state.item.order.meta, "corrected_art_width");
        }
        if (updatedArtWidth) {
            const width: Record<string, string> = JSON.parse(updatedArtWidth);
            const artWidth: string = Object.values(width)[0];
            if (artWidth) {
                this.setState({ updatedArtWidth: artWidth.toString() });
            }
        }

        let updatedArtHeight = attrVal(this.state.item.order.meta, "corrected_art_height_" + this.state.item.id);
        if (!updatedArtHeight) {
            updatedArtHeight = attrVal(this.state.item.order.meta, "corrected_art_height");
        }
        if (updatedArtHeight) {
            const height: Record<string, string> = JSON.parse(updatedArtHeight);
            const artHeight: string = Object.values(height)[0];
            if (artHeight) {
                this.setState({ updatedArtHeight: artHeight.toString() });
            }
        }

        let correctedArtTier = attrVal(this.state.item.order.meta, "corrected_art_tier_" + this.state.item.id);
        if (!correctedArtTier) {
            correctedArtTier = attrVal(this.state.item.order.meta, "corrected_art_tier");
        }
        if (correctedArtTier) {
            const tier: Record<string, string> = JSON.parse(correctedArtTier);
            const artTier = Object.values(tier)[0];
            if (artTier) {
                this.setState({ correctedArtTier: !!artTier });
            }
        }

        let artOrientation = attrVal(this.state.item.order.meta, "art_orientation_" + this.state.item.id);
        if (!artOrientation) {
            artOrientation = attrVal(this.state.item.order.meta, "art_orientation");
        }
        if (artOrientation) {
            const orientation: Record<string, string> = JSON.parse(artOrientation);
            const newOrientation = Object.values(orientation)[0];
            if (newOrientation) {
                this.setState({ artOrientation: newOrientation.toString() });
            }
        }
    }

    render(): JSX.Element {
        const itm = this.state.item;
        const frameSummary = attrVal(itm.attributes, "_Frame Summary");
        const artSummary = attrVal(itm.attributes, "_Art Summary");
        const itemPreviewImage = attrVal(itm.attributes, "_item_preview_image");
        const artFilename = attrVal(itm.attributes, "Art Filename");
        const artName = attrVal(itm.attributes, "Artwork Name");

        // const productName = attrVal(itm.attributes, '_Product Name');
        return (
            <Card className="line-item">
                <Card.Header>
                    {itm.order.name}-{itm.lineNum} - {itm.order.customerName} {" - "}
                    <strong>{itm.order.customerEmail}</strong>
                </Card.Header>
                <Card.Body>
                    <Tabs
                        defaultActiveKey="preview"
                        onSelect={() => this.setState({ imgUploadingErrorFiles: [] })}
                        id="photo-nav"
                        className="mb-3"
                        style={{ borderBottom: "1px solid #dee2e6" }}
                    >
                        <Tab eventKey="preview" title="Preview">
                            {frameSummary && <Card.Text>{frameSummary}</Card.Text>}
                            {artSummary && <Card.Text>{artSummary}</Card.Text>}
                            {itemPreviewImage && (
                                <div className="images">
                                    <img src={itemPreviewImage} key={itemPreviewImage} alt="Order Preview" />
                                </div>
                            )}
                            {artName && <Card.Text>Art Name: {artName}</Card.Text>}
                            {artFilename && <Card.Text>Filename: {artFilename}</Card.Text>}
                        </Tab>
                        <Tab eventKey="damage" title="Damage">
                            {frameSummary && <Card.Text>{frameSummary}</Card.Text>}
                            {artSummary && <Card.Text>{artSummary}</Card.Text>}
                            {itm.damagedImages && (
                                <div className="images">
                                    {itm.damagedImages.map(img => (
                                        <EditableImage
                                            src={img.url}
                                            key={img.url}
                                            onImageChanged={() => this.saveItemImage(img.url, "damage")}
                                            onImageDelete={() => this.deleteItemImage(img.url, "damage")}
                                        />
                                    ))}
                                </div>
                            )}
                            <div>
                                <div style={{ textAlign: "left" }}>
                                    <label htmlFor={"ipt" + itm.id}>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={this.handleDamagedPhotoButtonClick}
                                            disabled={this.state.imgUploading}
                                        >
                                            {this.state.imgUploading
                                                ? `Uploading: ${this.state.uploadProgress}%`
                                                : "+ Damage Photo"}
                                        </Button>
                                    </label>
                                    <input
                                        name="damage"
                                        type="file"
                                        accept="image/*,capture=camera"
                                        multiple={true}
                                        onChange={this.handleFileInputChange}
                                        ref={this.fileDamagedInputRef}
                                    />
                                    <div style={{ float: "right" }}>
                                        {this.state.damageStatusError ? (
                                            <label style={{ color: "red" }}>Damage Status Must Be Selected</label>
                                        ) : (
                                            <label>Select Damage Status</label>
                                        )}

                                        <Form>
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="concealed"
                                                checked={this.state.damageStatus === "concealed" ? true : false}
                                                label="Damage will be covered by frame or selected mat style - Proceed as Ordered"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="drymounting"
                                                checked={this.state.damageStatus === "drymounting" ? true : false}
                                                label="Damage may be minimized by dry mount - Proceed as Ordered"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="not-perfect"
                                                checked={this.state.damageStatus === "not-perfect" ? true : false}
                                                label="Art Condition is ‘Not Perfect, but minimal known damage’ - Proceed as Ordered"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="vintage"
                                                checked={this.state.damageStatus === "vintage" ? true : false}
                                                label="Art Condition is ‘Old, Vintage, full of imperfections’ - Proceed as Ordered"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="beyond"
                                                checked={this.state.damageStatus === "beyond" ? true : false}
                                                label="Damage is beyond repair, request replacement - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="concealed-mat"
                                                checked={this.state.damageStatus === "concealed-mat" ? true : false}
                                                label="Damage can be concealed - requesting mat style change - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="dry-mount-change"
                                                checked={this.state.damageStatus === "dry-mount-change" ? true : false}
                                                label="Damage may be minimized by dry mount - requesting mounting change - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="remains-damaged"
                                                checked={this.state.damageStatus === "remains-damaged" ? true : false}
                                                label="Artwork remains damaged - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                        </Form>

                                        {this.state.markDamagedHide ? (
                                            <>
                                                <div style={{ float: "right" }}>Mark as damaged?</div>
                                                <Button
                                                    variant="outline-success"
                                                    size="sm"
                                                    disabled={this.state.markDamagedLoading}
                                                    className="ml-2 pull-right"
                                                    onClick={this.handleMarkDamaged}
                                                    style={{ float: "right" }}
                                                >
                                                    Yes
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                disabled={this.state.markDamagedLoading}
                                                className="ml-2 pull-right"
                                                onClick={() => this.hideHandleMarkDamaged(true)}
                                                style={{ float: "right" }}
                                            >
                                                Damaged
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map(filename => (
                                            <span className="error" key={filename}>
                                                {filename}
                                            </span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artDamagedDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous Damage: {this.state.artDamagedDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="checkin" title="Checkin">
                            {frameSummary && <Card.Text>{frameSummary}</Card.Text>}
                            {artSummary && <Card.Text>{artSummary}</Card.Text>}

                            <div className="images">
                                {itm.images.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "checkin")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "checkin")}
                                    />
                                ))}
                            </div>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        textAlign: "left"
                                    }}
                                >
                                    <label htmlFor={"ipt" + itm.id}>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={this.handlePhotoButtonClick}
                                            disabled={this.state.imgUploading}
                                        >
                                            {this.state.imgUploading
                                                ? `Uploading: ${this.state.uploadProgress}%`
                                                : "+ Check in Photo"}
                                        </Button>
                                    </label>
                                    <input
                                        name="checkin"
                                        type="file"
                                        accept="image/*,capture=camera"
                                        multiple={true}
                                        onChange={this.handleFileInputChange}
                                        ref={this.fileInputRef}
                                        style={{ marginLeft: "10px" }}
                                    />
                                    <div style={{ marginLeft: "10px" }}>
                                        <label>Art Orientation</label>
                                        <Form>
                                            <Form.Check
                                                type="radio"
                                                name="art-orientation"
                                                value="portrait"
                                                checked={this.state.artOrientation === "portrait" ? true : false}
                                                label={
                                                    <span>
                                                        Portrait
                                                        <span style={{ fontSize: "1.5em", marginLeft: "0.5em" }}>
                                                            &#9647;
                                                        </span>
                                                    </span>
                                                }
                                                onChange={this.handleOrientationChanged}
                                                style={{ display: "flex", alignItems: "center" }}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="art-orientation"
                                                value="landscape"
                                                checked={this.state.artOrientation === "landscape" ? true : false}
                                                label={<span>Landscape &#9645;</span>}
                                                onChange={this.handleOrientationChanged}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="art-orientation"
                                                value="square"
                                                checked={this.state.artOrientation === "square" ? true : false}
                                                label={<span>Square &#9633;</span>}
                                                onChange={this.handleOrientationChanged}
                                            />
                                        </Form>
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <label>Incorrect Art Size?</label>
                                        <Form>
                                            <Form.Row>
                                                <Col>
                                                    <Form.Group controlId="formArtWidth">
                                                        <Form.Label>Correct Art Width</Form.Label>
                                                        <Form.Control
                                                            name="corrected-art-width"
                                                            type="text"
                                                            placeholder="Enter art width"
                                                            onChange={this.handleArtWidthChanged}
                                                            value={this.state.updatedArtWidth}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formArtHeight">
                                                        <Form.Label>Correct Art Height</Form.Label>
                                                        <Form.Control
                                                            name="corrected-art-height"
                                                            type="text"
                                                            placeholder="Enter art height"
                                                            onChange={this.handleArtHeightChanged}
                                                            value={this.state.updatedArtHeight}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="corrected-art-tier"
                                                        checked={this.state.correctedArtTier}
                                                        label="Changed Size Tier"
                                                        onChange={this.handleArtTierChanged}
                                                    />
                                                </Col>
                                            </Form.Row>
                                        </Form>

                                        {this.state.markArrivedHide ? (
                                            <>
                                                <div style={{ float: "right" }}>Mark as checked in?</div>
                                                <Button
                                                    variant="outline-success"
                                                    size="sm"
                                                    disabled={this.state.markArrivedLoading}
                                                    className="ml-2 pull-right"
                                                    onClick={this.handleMarkArrived}
                                                    style={{ float: "right" }}
                                                >
                                                    Yes
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                disabled={this.state.markArrivedLoading}
                                                className="ml-2 pull-right"
                                                onClick={() => this.hideHandleMarkArrived(true)}
                                                style={{ float: "right" }}
                                            >
                                                Check In
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map(filename => (
                                            <span className="error" key={filename}>
                                                {filename}
                                            </span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artReceivedDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous CheckIn: {this.state.artReceivedDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="qc" title="QC">
                            {frameSummary && <Card.Text>{frameSummary}</Card.Text>}
                            {artSummary && <Card.Text>{artSummary}</Card.Text>}
                            <div className="images">
                                {itm.qcImages.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "qc")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "qc")}
                                    />
                                ))}
                            </div>
                            <div style={{ textAlign: "left" }}>
                                <label htmlFor={"ipt" + itm.id}>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={this.handleQcPhotoButtonClick}
                                        disabled={this.state.imgUploading}
                                    >
                                        {this.state.imgUploading
                                            ? `Uploading: ${this.state.uploadProgress}%`
                                            : "+ QC Photo"}
                                    </Button>
                                </label>
                                <input
                                    name="qc"
                                    type="file"
                                    accept="image/*,capture=camera"
                                    multiple={true}
                                    onChange={this.handleFileInputChange}
                                    ref={this.fileQcInputRef}
                                />
                                {this.state.markQcHide ? (
                                    <>
                                        <div style={{ float: "right" }}>Mark as complete?</div>
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            disabled={this.state.markQcLoading}
                                            className="ml-2 pull-right"
                                            onClick={this.handleMarkQc}
                                            style={{ float: "right" }}
                                        >
                                            Yes
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        disabled={this.state.markQcLoading}
                                        className="ml-2 pull-right"
                                        onClick={() => this.hideHandleMarkQc(true)}
                                        style={{ float: "right" }}
                                    >
                                        Complete
                                    </Button>
                                )}
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map(filename => (
                                            <span className="error" key={filename}>
                                                {filename}
                                            </span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artQcDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous QC: {this.state.artQcDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        );
    }

    private handleArtWidthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            this.state.item.updatedArtWidth = event.target.value;
            this.setState({ updatedArtWidth: event.target.value });
        }
    };

    private handleArtHeightChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            this.state.item.updatedArtHeight = event.target.value;
            this.setState({ updatedArtHeight: event.target.value });
        }
    };

    private handleArtTierChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.item.correctedArtTier = event.target.checked;
        this.setState({ correctedArtTier: event.target.checked });
    };

    private handleOrientationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.item.artOrientation = event.target.value;
        this.setState({ artOrientation: event.target.value });
    };

    private hideHandleMarkArrived = (state: boolean) => {
        this.setState({ markArrivedHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markArrivedLoading && this.setState({ markArrivedHide: false });
        }, 4000);
    };

    private handleMarkArrived = async () => {
        this.setState({
            markArrivedLoading: true
        });
        try {
            console.log("Marking arrived for", this.state.item);
            this.state.item.updatedArtHeight = this.state.updatedArtHeight;
            this.state.item.updatedArtWidth = this.state.updatedArtWidth;
            await Api.markArtReceived(this.state.item);
            this.setState({ markArrivedHide: false });
            this.setState({
                markArrivedLoading: false
            });
            this.setState({ artReceivedDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markArrivedLoading: false
            });
        }
    };

    private handleDamageStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            this.setState({ damageStatusError: false });
            this.setState({ damageStatus: event.target.value });
        }
    };

    private hideHandleMarkDamaged = (state: boolean) => {
        if (this.state.damageStatus) {
            this.setState({ markDamagedHide: state });
            setTimeout(() => {
                // reset confirmation state if no confirmation comes in a reasonable window.
                !this.state.markDamagedLoading && this.setState({ markDamagedHide: false });
            }, 4000);
        } else {
            this.setState({ damageStatusError: true });
        }
    };

    private handleMarkDamaged = async () => {
        this.setState({ markDamagedLoading: true });
        try {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.item.damageStatus = this.state.damageStatus;
            await Api.markArtDamaged(this.state.item);
            this.setState({ markDamagedHide: false });
            this.setState({ markDamagedLoading: false });
            this.setState({ artDamagedDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markDamagedLoading: false
            });
        }
    };

    private hideHandleMarkQc = (state: boolean) => {
        this.setState({ markQcHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markQcLoading && this.setState({ markQcHide: false });
        }, 4000);
    };

    private handleMarkQc = async () => {
        this.setState({
            markQcLoading: true
        });
        try {
            await Api.markArtQc(this.state.item);
            this.setState({ markQcHide: false });
            this.setState({ markQcLoading: false });
            this.setState({ artQcDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markQcLoading: false
            });
        }
    };

    private handlePhotoButtonClick = () => {
        const fileInput = this.fileInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleDamagedPhotoButtonClick = () => {
        const fileInput = this.fileDamagedInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleQcPhotoButtonClick = () => {
        const fileInput = this.fileQcInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    // This method could be crammed down into EditableImage, but we pass
    // a handler down so EditableImage doesn't need to know about Api.
    private saveItemImage = async (newUrl: string, type: string) => {
        await Api.saveImagesForItem(this.state.item, [newUrl], type);
    };

    private deleteItemImage = async (image: string, type: string) => {
        try {
            await Api.deleteImageForItem(this.state.item, { url: image }, type);

            this.setState(state => {
                const {
                    item,
                    item: { images, damagedImages, qcImages }
                } = state;
                let index: number;
                switch (type) {
                    case "checkin": {
                        index = images.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            images.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.images = images;
                        break;
                    }
                    case "damage": {
                        index = damagedImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            damagedImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.damagedImages = damagedImages;
                        break;
                    }
                    case "qc": {
                        index = qcImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            qcImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.qcImages = qcImages;
                        break;
                    }
                    default: {
                        break;
                    }
                }

                return {
                    ...state,
                    item
                };
            });
        } catch (e) {
            console.log(`Unable to delete ${image}: ${e}`);
        }
    };

    private handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const type = e.target.name;
        if (!files || !files.length) return;

        this.setState({ imgUploading: true, imgUploadingErrorFiles: [], uploadProgress: 0 });

        let filesTotalSize = 0;
        let filesLoadedSize = 0;
        const onProgress = (e: AxiosProgressEvent) => {
            filesLoadedSize += e.bytes;
            this.setState({
                uploadProgress: Math.round((filesLoadedSize * 100) / filesTotalSize)
            });
        };

        const promises: Promise<string>[] = [];
        for (const file of files) {
            filesTotalSize += file.size;
            promises.push(
                new Promise(async (resolve, reject) => {
                    try {
                        const newUrl = await Api.putImage(file, file.name, onProgress);
                        resolve(newUrl);
                    } catch (e) {
                        reject(file.name);
                    }
                })
            );
        }
        const results = await Promise.allSettled(promises);
        const [resolvedUrls, rejectedFiles] = results.reduce<string[][]>(
            (acc, r) => {
                if (r.status === "fulfilled") {
                    acc[0].push(r.value);
                } else {
                    acc[1].push(r.reason);
                }
                return acc;
            },
            [[], []]
        );
        if (resolvedUrls.length) {
            try {
                await Api.saveImagesForItem(this.state.item, resolvedUrls, type);
            } catch (e) {
                console.log("saveImagesForItem e:", e);
                // Save failed, so move all resolvedUrls to rejectedFiles
                while (resolvedUrls.length) {
                    const failedUrl = resolvedUrls.shift();
                    rejectedFiles.push(decodeURIComponent(basename(failedUrl as string) as string));
                }
            }
            for (const newUrl of resolvedUrls) {
                switch (type) {
                    case "checkin": {
                        this.state.item.images.push({ url: newUrl });
                        break;
                    }
                    case "damage": {
                        if (this.state.item.damagedImages) {
                            this.state.item.damagedImages.push({ url: newUrl });
                        }
                        break;
                    }
                    case "qc": {
                        if (this.state.item.qcImages) {
                            this.state.item.qcImages.push({ url: newUrl });
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
        this.setState({
            item: this.state.item,
            imgUploading: false,
            imgUploadingErrorFiles: rejectedFiles
        });
    };
}
