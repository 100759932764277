import axios, { axiosS3 } from "./axios";
import { AxiosError, AxiosProgressEvent } from "axios";
import { Image, OrderItem } from "./models";

// we'll just use a single instance, configured how we want.
const http = axios;

interface Credentials {
    username: string;
    password: string;
}

// interface Order {
//     id: number;
//     customer: string;
//     images: string[];
// }

class Api {
    /**
     * Ping backend with simple get request
     */
    async ping(): Promise<boolean> {
        try {
            await http.get("/?t=" + Date.now().toString(10));
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Make a test request with the provided creds
     */
    async testCredentials(creds: Credentials): Promise<boolean> {
        const cfg = { auth: creds };
        try {
            await http.get("/", cfg);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    /**
     * Test provided credentials, and persist them on success.
     */
    async authenticate(username: string, password: string): Promise<boolean> {
        const creds = { username, password };
        const authResult = await this.testCredentials(creds);
        if (authResult) {
            axios.defaults.auth = creds;
            localStorage.setItem("credentials", JSON.stringify(creds));
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    logout(): void {
        delete axios.defaults.auth;
        localStorage.removeItem("credentials");
    }

    async getOrderItems(): Promise<{ items: OrderItem[] }> {
        return (await http.get(`/order-items`)).data;
    }

    async searchOrderItems(q?: string): Promise<{ items: OrderItem[] }> {
        return (await http.get(`/order-items?q=${q}`)).data;
    }

    async getSignedUrl(filename: string, type: string): Promise<string> {
        const url = `/photo/upload-url?contentType=${type}&filename=${encodeURIComponent(filename)}`;
        return (await http.get(url)).data.url;
    }

    /**
     * What if this also returned the file URL?
     */
    async putImage(
        imgFile: File | Blob,
        fileName: string,
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
    ): Promise<string> {
        const putToSignedUrl = async () => {
            const signedUrl = await this.getSignedUrl(fileName, imgFile.type);
            await axiosS3.put(signedUrl, imgFile, {
                headers: { "Content-Type": imgFile.type },
                responseType: "blob",
                onUploadProgress
            });
            return signedUrl.split("?")[0];
        };
        let url;
        try {
            url = await putToSignedUrl();
        } catch (e) {
            // Try again if there was a network problem
            if (["ETIMEDOUT", "ERR_NETWORK"].includes((e as { code: string }).code)) {
                url = await putToSignedUrl();
            }
            throw e;
        }
        return url;
    }

    async saveImagesForItem(item: OrderItem, imageUrls: string[], type: string) {
        return await http.post(
            `/order/${encodeURIComponent(item.order.id)}/item/${encodeURIComponent(item.id)}/type/${encodeURIComponent(
                type
            )}/images`,
            imageUrls
        );
    }

    async deleteImageForItem(item: OrderItem, image: Image, type: string) {
        return await http.delete(
            `/order/${encodeURIComponent(item.order.id)}/item/${encodeURIComponent(item.id)}/type/${encodeURIComponent(
                type
            )}/${encodeURIComponent(image.url)}`
        );
    }

    async markArtReceived(item: OrderItem) {
        const url = `/order/${encodeURIComponent(item.order.id)}/item/${encodeURIComponent(item.id)}/received-art`;
        const data = {
            updatedArtWidth: item.updatedArtWidth || null,
            updatedArtHeight: item.updatedArtHeight || null,
            correctedArtTier: item.correctedArtTier || null,
            artOrientation: item.artOrientation || null
        };
        return await http.post(url, data);
    }

    async markArtDamaged(item: OrderItem) {
        const url = `/order/${encodeURIComponent(item.order.id)}/item/${encodeURIComponent(
            item.id
        )}/damagestatus/${encodeURIComponent(item.damageStatus)}/damaged-art`;
        return await http.post(url);
    }

    async markArtQc(item: OrderItem) {
        const url = `/order/${encodeURIComponent(item.order.id)}/item/${encodeURIComponent(item.id)}/qc-art`;
        return await http.post(url);
    }
}

export default new Api();

interface GetSignedUrlResult {
    success: boolean;
    url: string;
}
