import React, { CSSProperties, FunctionComponent } from "react";

import logo from "../logo.svg";

interface Props {
    logoWhite?: boolean;
}

export const Loading: FunctionComponent<Props> = props => {
    const style: CSSProperties = {};
    if (props.logoWhite) {
        style.filter = "invert(100%)";
    }

    return (
        <div className="loading-screen">
            <img src={logo} className="logo loading" width={200} alt="loading" style={style} />
            <div>Loading...</div>
        </div>
    );
};
